<template>
    <section
        v-if="!(breakpoints?.isMobile && slice.primary.hideInMobile) && slice.primary"
        :id="slice.primary.anchorName || undefined"
        ref="section"
        class="slice cta-wrapper"
        :class="{
            plain: slice.variation === 'plainBackground',
            'theme-malt': theme === 'malt',
            'theme-strategy': theme === 'strategy',
            'theme-supermalter': theme === 'supermalter',
        }"
        aria-label="call-to-action"
    >
        <PrismicRichText :field="slice.primary.title" class="title" />
        <PrismicRichText v-if="slice.variation === 'plainBackground'" :field="slice.primary.description" class="description" />

        <div class="buttons-wrapper">
            <VJoyButton
                v-if="isFilledKeyText(slice.primary.primaryCtaLink) && isFilledKeyText(slice.primary.primaryCtaLinkText)"
                :variant="ctaVariant"
                :href="slice.primary.primaryCtaLink"
                data-source-component="call-to-action"
            >
                {{ slice.primary.primaryCtaLinkText }}
            </VJoyButton>
            <VJoyButton
                v-if="isFilledKeyText(slice.primary.secondaryCtaLink) && isFilledKeyText(slice.primary.secondaryCtaLinkText)"
                variant="white"
                :href="slice.primary.secondaryCtaLink"
                data-source-component="call-to-action"
            >
                {{ slice.primary.secondaryCtaLinkText }}
            </VJoyButton>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {computed, ref} from 'vue';
    import {useComponentTracking} from '#imports';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import {usePrismicTypeGuards} from '../../composables/shared/useTypeGuards';
    import {useBreakpoints} from '../../composables/shared/useBreakpoints';
    import type {Content} from '@prismicio/client';
    import {VJoyButton, type TJoyButtonVariants} from '@maltjoy/core-vue';

    const section = ref(null);
    const breakpoints = useBreakpoints();
    const {isFilledKeyText} = usePrismicTypeGuards();

    const props = defineProps(getSliceComponentProps<Content.CallToActionSliceSlice>());

    const isMaltStrategyTheme = props.slice.variation === 'default' && props.slice.primary.maltStrategyTheme;
    const theme = ref(isMaltStrategyTheme ? 'strategy' : props.slice.primary.theme);
    const ctaVariants = ref({
        malt: 'primary',
        strategy: 'main',
        supermalter: 'admin',
    });

    const ctaVariant = computed<TJoyButtonVariants>(() => ctaVariants.value[theme.value] as TJoyButtonVariants);

    useComponentTracking(section);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    @import '../../scss/variables.scss';

    .cta-wrapper {
        @include flex-column-center;
        $background-width: 1658px;
        $visible-background-width: 1338px;
        width: 100%;
        padding: 100px 20px 90px;
        font-family: var(--joy-font-family);
        background-image: url('./cta-shapes.svg');
        background-repeat: no-repeat;
        background-size: auto 590px;
        min-height: 590px;

        //lots of breakpoints to avoid white text and white button to be out of the shape while preserving ratio as much as possible

        //if screen is too big, center the shape
        background-position: center;

        //if screen is small enough, stick the shape to the left, but cut it less if screen is still quite big
        @media (max-width: 1920px) {
            --background-position: center right calc(100vw - #{$visible-background-width} - 100px);
            background-position: var(--background-position);
        }

        //when space on the right starts to be normal, cut the shape more on the left
        @media (max-width: calc(#{$visible-background-width} / 0.80 + 100px)) {
            --background-position: center right 20vw;
            background-position: var(--background-position);
        }

        //when shape is properly cut, reduce space on the right
        @media (max-width: calc(#{$visible-background-width} / 0.80)) {
            --background-position: center right calc(100vw - #{$visible-background-width});
            background-position: var(--background-position);
        }

        //cut the left of the shape if it is too big to avoid the right part beeing cut
        @media (max-width: $visible-background-width) {
            background-position: center right;
        }

        //center and cut on mobile
        @media (max-width: $mobile-breakpoint) {
            background-position: center;
            min-height: unset;
            background-size: auto 100%;
            padding: 125px $margin-section-mobile 110px;
        }

        .title h2 {
            text-align: center;
            color: white;
            @media (max-width: $mobile-breakpoint) {
                margin-bottom: 32px;
            }
        }

        .buttons-wrapper {
            width: 100%;
            > a {
                width: 100%;
                margin-bottom: var(--joy-core-spacing-5);
            }
            @include mq.screen_sm(min) {
                display: flex;
                justify-content: center;
                gap: var(--joy-core-spacing-5);
                max-width: 620px;
                margin: 0 auto;
                > a {
                    width: auto;
                    margin-bottom: 0;
                }
            }
        }

        &:not(.plain) {
            .title h2 {
                @include secondary-title;
                color: white;
                max-width: 620px;
                margin-bottom: 48px;
            }
        }
        &.plain {
            padding: 110px 0px;
            min-height: unset;
            background-image: none;
            background-color: var(--joy-color-primary-50);
            @media (max-width: $mobile-breakpoint) {
                padding: 50px 0px;
            }
            .title {
                @include content-width;

                h2 {
                    margin-bottom: var(--joy-core-spacing-6);
                }
            }
            .description {
                @include content-width;

                margin-bottom: var(--joy-core-spacing-10);
                text-align: center;
                * {
                    color: white;
                }
            }
            .buttons-wrapper {
                @include content-width;
                justify-content: center;
                @media (max-width: $mobile-breakpoint) {
                    padding: 0px 20px;
                }
            }
        }

        &.theme {
            &-strategy {
                &:not(.plain) {
                    background-image: url('./cta-shapes-ms.svg');
                }
                &.plain {
                    background-color: var(--joy-color-secondary-50);
                }
            }
            &-supermalter {
                &:not(.plain) {
                    background-image: url('./cta-shapes-supermalter.svg');
                }
                &.plain {
                    background-color: var(--joy-color-primary-50);
                }
                .joy-button_primary {
                    background-color: var(--joy-color-brand-secondary-30);
                    &:hover {
                        background-color: var(--joy-color-brand-secondary-50);
                    }
                }
            }
        }
    }
</style>
